var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-tabs',{attrs:{"grow":"","color":"secundary"},model:{value:(_vm._subTab),callback:function ($$v) {_vm._subTab=$$v},expression:"_subTab"}},[_c('v-tab',[_vm._v(" Reclamação ")]),_c('v-tab',[_vm._v(" Qualidade dos Serviços ")]),_c('v-tab',[_vm._v(" Técnicos ")])],1),_c('v-tabs-items',{staticStyle:{"background-color":"transparent !important"},model:{value:(_vm._subTab),callback:function ($$v) {_vm._subTab=$$v},expression:"_subTab"}},[_c('v-tab-item',[_c('layout-visao-global',{attrs:{"indicadores":[
          'qrt',
          'prp',
          'QTD Reclamações Totais',
          'QTD Reclamações Procedentes' ],"dadosMensais":_vm.dadosMensais,"dadosTotais":_vm.dadosTotais,"categories":_vm.categories,"traducaoIndicadores":_vm.traducaoIndicadores}})],1),_c('v-tab-item',[_c('layout-visao-global',{attrs:{"indicadores":[
          'psfpg',
          'psfpt-crp',
          'psfpt-cri',
          'psfpt-rmp',
          'psfpt-rmc',
          'Total Compensação' ],"dadosMensais":_vm.dadosMensais,"dadosTotais":_vm.dadosTotais,"categories":_vm.categories,"traducaoIndicadores":_vm.traducaoIndicadores}})],1),_c('v-tab-item',[_c('layout-visao-global',{attrs:{"indicadores":['plna', 'drpe', 'drce'],"dadosMensais":_vm.dadosMensais,"dadosTotais":_vm.dadosTotais,"categories":_vm.categories,"traducaoIndicadores":_vm.traducaoIndicadores}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }